import { createQueryStringUrl, get, post, patch, put } from '../helpers'

export default {
  mergeAccounts(data) {
    return post('api/admin/merge_accounts', { data })
  },

  refetchOrders(data) {
    return patch('api/admin/reload_orders', { data })
  },

  // Identifier: May be an email or an ID
  adminGetUsersBySellerIdentifier(identifier) {
    const params = {
      identifier
    }
    const url = createQueryStringUrl('api/admin/user/seller', params)
    return get(url)
  },

  resyncSellerProducts(data) {
    return patch('api/admin/user/resync_products', { data })
  },

  resyncProfitOverviewCOGS(data) {
    return patch('api/admin/reload_cogs', { data })
  },

  refetchReshippings(data) {
    return patch('api/admin/reload_reshippings', { data })
  },

  resetSync(id) {
    return patch(`api/admin/amazon_seller_accounts/${id}/reset_sync`)
  },

  getApiKeys(user_id) {
    const params = {
      user_id,
      page: 1,
      per_page: 100
    }
    const url = createQueryStringUrl('api/admin/api_keys', params)

    return get(url)
  },

  revokeApiKey(id) {
    return put(`api/admin/api_keys/${id}/revoke`)
  },

  getApiRequestCounts(membershipId) {
    const params = {
      membership_id: membershipId
    }
    const url = createQueryStringUrl('api/admin/api_request_counts', params)

    return get(url)
  }
}
