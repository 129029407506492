import { get, post } from '../helpers'

export default {
  getStripeCoupons() {
    return get('api/admin/stripe_coupons')
  },

  adminApplyStripeCoupon(data) {
    return post('api/admin/user_stripe_coupons', { data })
  }
}
