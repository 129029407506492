import { get } from './helpers'

export default {
  getAcademyGroups(itemId = null) {
    let path = 'api/academy_groups'
    if (itemId) {
      path += `?item_id=${itemId}`
    }
    return get(path)
  }
}
