import { buildFullPath } from '../build_paths'
import { get, put, destroy } from './helpers'

export default {
  getAlerts(params) {
    const path = buildFullPath('api/alerts', params)
    return get(path)
  },

  updateAlert(data) {
    return put(`api/alerts/${data.id}`, { data })
  },

  deleteAlert(id) {
    return destroy(`api/alerts/${id}`)
  }
}
