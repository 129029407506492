import { formatAcademyItem } from 'helpers/admin/academy'
import { get, post, put, destroy, createQueryStringUrl } from '../helpers'

export default {
  getAcademyAdminGroups() {
    return get('api/admin/academy_groups')
  },

  getAcademyAdminCourses(groupId) {
    return get(`api/admin/courses?academy_group_id=${groupId}`)
  },

  getAcademyAdminLessons(params) {
    const path = createQueryStringUrl(`api/admin/lessons`, params)
    return get(path)
  },

  createAcademyGroup(data) {
    return post(`api/admin/academy_groups`, { data })
  },

  createAcademyCourse(data) {
    return post(`api/admin/courses`, { data })
  },

  createAcademyLesson(data) {
    return post(`api/admin/lessons`, { data })
  },

  updateAcademyGroup(data) {
    return put(`api/admin/academy_groups/${data.id}`, { data })
  },

  updateAcademyCourse(data) {
    return put(`api/admin/courses/${data.id}`, {
      data: formatAcademyItem(data)
    })
  },

  updateAcademyLesson(data) {
    return put(`api/admin/lessons/${data.id}`, {
      data: formatAcademyItem(data)
    })
  },

  deleteAcademyGroup(groupId) {
    return destroy(`api/admin/academy_groups/${groupId}`)
  },

  deleteAcademyCourse(courseId) {
    return destroy(`api/admin/courses/${courseId}`)
  },

  deleteAcademyLesson(lessonId) {
    return destroy(`api/admin/lessons/${lessonId}`)
  }
}
