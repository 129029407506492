import academy from './academy'
import campaigns from './campaigns'
import orders from './orders'
import stripeCoupons from './stripe_coupons'

export default {
  ...academy,
  ...orders,
  ...stripeCoupons,
  ...campaigns
}
