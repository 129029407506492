import { get, post } from './helpers'

export default {
  fetchChecklist() {
    return get('api/checklists')
  },

  checkItem(itemId) {
    return post('api/user_checklist_items', {
      data: {
        checklist_item_id: itemId
      }
    })
  },

  applyCoupon() {
    return post('api/user_checklists/apply_coupon')
  }
}
