import { get } from '../helpers'
import { buildFullPath } from '../../build_paths'

export default {
  getAdminCampaignsAndBasePlans(params) {
    const path = buildFullPath(
      'api/admin/subscription_plans/campaigns_plus_base_plans',
      params
    )
    return get(path)
  }
}
